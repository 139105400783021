/* Simple Justified ( https://tailwindui.com/components/marketing/sections/cta-sections#component-90c79fbd0596cc4e601da985ca825994 ) */

import React from "react";
import SbEditable from "storyblok-react";
import Button from "src/components/Button";
import Heading from "src/components/Heading";
import { BackgroundColour, TitleColour, ButtonColour } from "src/utils/ColourUtilities"

const SimpleJustified = ({ blok }) => {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div className={`${BackgroundColour(blok.colour)}`}>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <div className="">
                    <Heading size="h1" as="h3" className={`${TitleColour(blok.colour)}`}>{blok.top_title}</Heading>
                    <p className="text-lg font-body leading-6">{blok.bottom_title}</p>
                </div>
                <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 md:flex-row flex-col gap-3">
                    {blok.left_btn_label &&
                        <div>
                            <Button
                                to={blok.left_btn_url?.cached_url}
                                className={`${ButtonColour(blok.colour)}`}
                                >
                                {blok.left_btn_label}
                            </Button>
                        </div>
                    }
                    {blok.right_btn_label &&
                        <div>
                            <Button
                                to={blok.right_btn_url.cached_url}
                                className={`${ButtonColour(blok.colour)}`}
                                >
                                {blok.right_btn_label}
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
      </SbEditable>
    );
  };
  
  export default SimpleJustified;
