import React from 'react'
import SbEditable from "storyblok-react";
import Heading from "src/components/Heading"
import { BackgroundColour, TitleColour, SliderArrowColour } from "src/utils/ColourUtilities"
import Slider from "react-slick";

const OffWhiteGrid = ({blok}) => {

    const NextArrow = ({ onClick }) => {
        return(
            <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0" className={`${SliderArrowColour(blok.colour)} w-9 h-9 rounded-full flex justify-center items-center absolute xl:-right-20 right-[calc(50%-46px)] xl:top-[calc(50%-18px)] xl:bottom-0 bottom-0`}>
                <i className="fal fa-long-arrow-right cursor-pointer transition duration-300 text-lg"></i>
            </div>
        )
    }
    
    const PrevArrow = ({ onClick }) => {
        return(
            <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0" className={`${SliderArrowColour(blok.colour)} w-9 h-9 rounded-full flex justify-center items-center absolute xl:-left-20 left-[calc(50%-46px)] xl:top-[calc(50%-18px)] xl:bottom-0 bottom-0`}>
                <i className="fal fa-long-arrow-left cursor-pointer transition duration-300 text-lg"></i>
            </div>
        )
    }

    const settings = {
        className: "xl:pb-0 pb-16",
        infinite: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToShow: 1,
        speed: 500,
        rows: 2,
        slidesPerRow: 3,
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                rows: 3,
                slidesPerRow: 2,
              }
            },
          ]
    };

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`${BackgroundColour(blok.colour)}`}>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h3'} className={`${TitleColour(blok.colour)} text-center max-w-2xl mx-auto !mb-8`}>{blok.title}</Heading>
                <Slider {...settings}>
                    {blok.logos.map((item, index) => {
                            return (
                                <div className="p-1 w-full h-32 focus:outline-none outline-none" key={index}>
                                    <div className="h-full w-full flex justify-center items-center bg-[#F5F5F5]">
                                        {<img className="h-[5rem]" src={item.image.filename} loading="lazy" alt={item.alt}/>}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                </div>
            </div>
        </SbEditable>
    )
  };

export default OffWhiteGrid;
  