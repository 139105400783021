import React from "react";
import Placeholder from "./Placeholder";
import SimpleCentered from "./SimpleCentered";
import SimpleOnBrand from "./SimpleOnBrand";
import WithFeatureList from "./WithFeatureList";
import GridOnBrand from "./GridOnBrand"
import OffWhiteGrid from "./OffWhiteGrid"
import TwoColumnWithSignUpPosts from "./TwoColumnWithSignUpPosts";
import TwoColumnWithSignUpCaseStudies from "./TwoColumnWithSignUpCaseStudies";
import TwoColumnWithSignUpCourses from "./TwoColumnWithSignUpCourses";

/* Marketing / PageSections / ContentSections */
import TwoColumns from "./Marketing/PageSections/ContentSections/TwoColumns";
import TwoColumnsWithImage from "./Marketing/PageSections/ContentSections/TwoColumnsWithImage";
import Stat from "./Marketing/PageSections/ContentSections/Stat";

/* Marketing / PageSections / Contact Sections */
import ContactSplitTwoTone from "./Marketing/PageSections/ContactSections/SplitTwoTone";
import CourseEnquiryForm from "./Marketing/PageSections/ContactSections/CourseEnquiryForm";

/* Marketing / PageSections / CTA Sections */
import SimpleJustified from "./Marketing/PageSections/CTASections/simple-justified";

/* Marketing / PageSections / FAQs */
import OffsetSupportingText from "./Marketing/PageSections/FAQs/offset-supporting-text";

/* Marketing / PageSections / Testimonials */
import TestimonialsSimpleCentered from "./Marketing/PageSections/Testimonials/SimpleCentered";

/* TBB */
import Hero from "./TBB/Heros/Hero";
import SlimlineHero from "./TBB/Heros/SlimlineHero";
import StarRating from "./TBB/Heros/StarRating";

/* Custom */
import GoogleMap from "./Custom/GoogleMap";
import Video from "./Custom/Video";
import YouTubeVideo from "./Custom/YouTubeVideo"
import Audio from "./Custom/Audio";

const Components = {
  "hero": Hero,
  "slimline-hero": SlimlineHero,
  "star-rating": StarRating,
  "simple-centered": SimpleCentered,
  "simple-on-brand" : SimpleOnBrand,
  "with-feature-list" : WithFeatureList,
  "grid-on-brand" : GridOnBrand,
  "off-white-grid" : OffWhiteGrid,
  "two-column-with-sign-up-posts" : TwoColumnWithSignUpPosts,
  "two-column-with-sign-up-case-studies" : TwoColumnWithSignUpCaseStudies,
  "two-column-with-sign-up-courses" : TwoColumnWithSignUpCourses,
  /* Marketing / PageSections / ContentSections */
  "two-columns": TwoColumns,
  "two-columns-with-image": TwoColumnsWithImage,
  stat: Stat,
  /* Marketing / PageSections / CTA Sections */
  "simple-justified": SimpleJustified,
  /* Marketing / PageSections / FAQs */
  "offset-with-supporting-text": OffsetSupportingText,
  /* Marketing / PageSections / Contact Sections */
  "split-two-tone": ContactSplitTwoTone,
  "course-enquiry-form": CourseEnquiryForm,
  /* Marketing / PageSections / Testimonials */
  "testimonial-simple-centered": TestimonialsSimpleCentered,
  /* Custom */
  "video": Video,
  "youtube-video": YouTubeVideo,
  "google-map":GoogleMap,
  "audio": Audio
};

const DynamicComponent = ({ blok, pageComponent, firstBlock }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component blok={blok} key={blok._uid} pageComponent={pageComponent} isFirstBlock={blok._uid === firstBlock ? true : false} />;
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null;
};

export default DynamicComponent;
