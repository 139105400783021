import { graphql, useStaticQuery } from 'gatsby';

const GetCourses = ( items ) => {

    let filteredPosts = [];
    const isResolved = typeof items[0] !== 'string'
   
    const data = useStaticQuery(graphql`
      {
        posts: allStoryblokEntry(filter: {field_component: {eq: "course"}}) {
          edges {
            node {
              uuid
              name
              full_slug
              content
            }
          }
        }
      }
    `)

    if(!isResolved) {
      filteredPosts = data.posts.edges
      .filter(p => items.indexOf(p.node.uuid) > -1);
   
      filteredPosts = filteredPosts.map((p, i) => {
        const content = p.node.content
        const newContent = typeof content === 'string' ? JSON.parse(content) : content
        p.node.content = newContent
        return p.node
      })
    }
   
    return items = isResolved ? items : filteredPosts

}

export default GetCourses