import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText';
import Label from './Label';
import Heading from './Heading';

const SimpleCentered = ({ blok , pageComponent, isFirstBlock }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="max-w-3xl">
                        <Label className="text-pink mb-2">{blok.label}</Label>
                        <Heading size="h1" as={(['post', 'course'].includes(pageComponent) && isFirstBlock) ? 'h1' : blok.title_h_tag ? blok.title_h_tag : 'h2'} className="text-darkblue mb-6 lg:text-4.5xl text-3xl lg:leading-9 leading-[1.875rem] !font-black">{blok.title}</Heading>
                        <div className="prose max-w-3xl font-body leading-6 text-gray"><RichText>{blok.content}</RichText></div>
                    </div>
                </div>
            </div>        
        </SbEditable>
    )
}

export default SimpleCentered