import React from "react";
import SbEditable from "storyblok-react";
import Heading from 'src/components/Heading'
import RichText from "src/utils/RichText";
import Link from "gatsby-link";
import { BackgroundColour, TitleColour, HrColour, ArrowColour } from "src/utils/ColourUtilities"
import GetAnyDescription from "../utils/GetAnyDescription";
import resolveLink from 'src/utils/resolveLink'

const GridOnBrand = ({blok}) => {
    
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`${BackgroundColour(blok.colour)}`}>
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:pt-24 lg:px-8">
                <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h2'} className={`${TitleColour(blok.colour)} max-w-2xl`}>{blok.title}</Heading>
                <div className="prose leading-6 font-body max-w-2xl">
                    <RichText>{blok.description}</RichText>
                </div>
                <hr className={`${HrColour(blok.colour)} mt-8`}/>
                <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:mt-10 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {blok.items.map((item, index) => (
                    <Link to={item.attached_file && item.attached_file.filename ? item.attached_file.filename : resolveLink(item.target.cached_url)} key={index} className="group" target={item.attached_file ? "_blank" : ''}>
                        <div className="mt-6">
                            <Heading size="h2" as="h3" className={`${TitleColour(blok.colour)}`}>{item.title}</Heading>
                            <div className="prose leading-6 font-body mb-4">
                                {item.description ? item.description : GetAnyDescription(item.target.id)}
                            </div>
                            <div className={`h-12 w-12 flex justify-center items-center group`}>
                                <div className="h-full w-full relative bg-white opacity-50 group-hover:opacity-100 duration-300"></div>
                                <i className={`far fa-long-arrow-right text-xl absolute duration-300 ${ArrowColour(blok.colour)}`}></i>
                            </div>
                        </div>
                    </Link>
                ))}
                </div>
            </div>
            </div>
        </SbEditable>
    )
};

export default GridOnBrand;
  