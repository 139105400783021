import React from 'react'
import SbEditable from 'storyblok-react'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'
import GetCourses from 'src/utils/GetCourses'
import TwoColumnWithSignUpItem from 'src/components/TwoColumnWithSignUpItem'

const TwoColumnWithSignUpCourses = ({blok}) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative mx-auto divide-y divide-pink max-w-7xl">
        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end">
            <div>
                <Label className="text-pink mb-2 empty:hidden">{blok.label}</Label>
                <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h3'} className="text-darkblue mb-0">{blok.title}</Heading>
            </div>
            {blok.button_text &&
              <div>
                  <Button to={blok.button_target.cached_url} className="lg:mt-0 mt-4" type="pink">{blok.button_text}</Button>
              </div>
            }
        </div>
        <div className="mt-6 pt-10 grid gap-12 lg:grid-cols-2 lg:gap-x-16 lg:gap-y-12">
            {GetCourses(blok.courses).map((item, index) => {
                return(
                    <TwoColumnWithSignUpItem {...item} key={index}/>
                ) 
            })}
        </div>
      </div>
    </div>
    </SbEditable>
  )
};

export default TwoColumnWithSignUpCourses;
  