
/*  TWO COLUMNS WITH IMAGE ( https://tailwindui.com/components/marketing/sections/content-sections#component-39249d619dcd4a64f3089bf550369572 )  */

import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import FluidImage from 'src/utils/FluidImage'
import { CameraIcon } from '@heroicons/react/solid'
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'

const TwoColumnsWithImage = ({ blok }) => {

    const caption = blok.caption_target ? <Link to={resolveLink(blok.caption_target.cached_url)} className="font-semibold text-pink hover:text-darkblue duration-300">{blok.caption_location}</Link> : <span className="font-semibold text-pink">{blok.caption_location}</span>

    return (
        <SbEditable content={blok}>
            <div className="bg-white overflow-hidden">
                <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                        <div>
                            <Label className="mb-2 text-pink">{blok.label}</Label>
                            <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h2'} className="text-darkblue">{blok.title}</Heading>
                        </div>
                    </div>
                    <div className="mt-6 lg:grid lg:grid-cols-2 lg:gap-16">
                        <div className={`relative ${blok.flip ? '' : 'lg:row-start-1 lg:col-start-2'}`}>
                            <div className="relative text-base">
                            <figure>
                                <FluidImage className="object-cover object-center" image={blok.image}/>
                                {blok.caption_location && 
                                    <figcaption className="mt-3 flex text-sm text-gray font-body align-center">
                                        <CameraIcon className="flex-none w-5 h-5 text-pink" aria-hidden="true" />
                                        <span className="ml-2 leading-normal">Photography at {caption}</span>
                                    </figcaption>
                                }
                            </figure>
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-0">
                            <div className="text-base max-w-prose mx-auto lg:max-w-none">
                                </div>
                                <div className="mt-5 prose text-gray leading-6 font-body lg:row-start-1 lg:col-start-1">
                                    <RichText>{blok.content}</RichText>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default TwoColumnsWithImage