import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText'
import Heading from 'src/components/Heading'
import Label from 'src/components/Label'
import { CheckIcon } from '@heroicons/react/outline'

const WithFeatureList = ({blok}) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
        <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div>
            <Label className="text-pink mb-2">{blok.label}</Label>
            <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h2'} className="text-darkblue">{blok.title}</Heading>
            <div className="mt-4 prose leading-6 text-gray font-body">
                <RichText>{blok.description}</RichText>
            </div>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-row sm:gap-x-6 sm:gap-y-6 lg:gap-x-6">
                {blok.features.map((item, index) => (
                <div key={index} className="relative">
                    <dt>
                        <CheckIcon className="absolute h-6 w-6 text-pink" aria-hidden="true" />
                    </dt>
                    <dd className="ml-9 prose text-gray leading-6 font-body">
                        <RichText>{item.content}</RichText>
                    </dd>
                </div>
                ))}
            </dl>
            </div>
        </div>
        </div>
    </SbEditable>
  )
};

export default WithFeatureList;
