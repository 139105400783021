import React from 'react'
import SbEditable from "storyblok-react";
import Stat from "src/components/Stat"
import { BackgroundColour } from "src/utils/ColourUtilities"

const SimpleOnBrand = ({ blok }) => {
    return(
        <SbEditable content={blok}>
            <div className={`relative ${BackgroundColour(blok.colour)}`}>
                <div className="py-12 lg:py-16">
                    <div className="flex flex-row flex-wrap lg:flex-nowrap max-w-7xl mx-auto relative lg:space-x-32">
                        <div className="flex md:flex-row flex-col w-full px-6 py-4 lg:gap-12 gap-8">
                            {
                                blok.stats.map((item, index) => {
                                    return(
                                        <Stat blok={item} key={index} colour={blok.colour}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default SimpleOnBrand