import React from 'react'
import SbEditable from "storyblok-react"
import { BackgroundColour, TitleColour, LabelColour, ArrowButtonColour } from "src/utils/ColourUtilities"
import Label from '../../Label'
import RichText from '/src/utils/RichText'
import Button from '/src/components/Button'

const SlimlineHero = ({ blok }) => {

    const classes = !blok.condensed ?
    `font-display 2xl:text-[9rem] xl:text-[8rem] lg:text-[6rem] md:text-7xl sm:text-6xl text-5xl 2xl:leading-[8rem] xl:leading-[7rem] lg:leading-[5.5rem] md:leading-[4rem] sm:leading-[3rem] leading-[3rem] font-black italic uppercase` :
    `font-display md:text-7xl text-5xl font-black italic uppercase`

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`relative ${BackgroundColour(blok.colour)}`}>
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 flex flex-col justify-center items-start h-full">
                    <div className={`${blok.condensed ? 'max-w-3xl' : ''}`}>
                    {blok.label && <Label className={`mb-4 ${LabelColour(blok.colour)}`}>{blok.label}</Label>}
                    <h1 className={`${classes} ${TitleColour(blok.colour)}`}>{blok.title}</h1>
                    {blok.description &&
                        <div className="lg:mt-12 mt-6 lg:text-lg prose leading-6 font-body"> 
                            <RichText>{blok.description}</RichText>
                        </div>
                    }
                    {blok.button_text &&
                        <Button to={blok.button_target.cached_url} anchor={blok.button_target?.anchor} className={`mt-4 ${ArrowButtonColour(blok.colour)}`} arrow>{blok.button_text}</Button>
                    }
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default SlimlineHero