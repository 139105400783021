import React from "react";
import SbEditable from "storyblok-react";
import { BackgroundColour, TitleColour } from "src/utils/ColourUtilities";
import Stars4_1 from "/static/star_ratings/summit_stars_4_1.svg";
import Stars4_2 from "/static/star_ratings/summit_stars_4_2.svg";
import Stars4_3 from "/static/star_ratings/summit_stars_4_3.svg";
import Stars4_4 from "/static/star_ratings/summit_stars_4_4.svg";
import Stars4_5 from "/static/star_ratings/summit_stars_4_5.svg";
import Stars4_6 from "/static/star_ratings/summit_stars_4_6.svg";
import Stars4_7 from "/static/star_ratings/summit_stars_4_7.svg";
import Stars4_8 from "/static/star_ratings/summit_stars_4_8.svg";
import Stars4_9 from "/static/star_ratings/summit_stars_4_9.svg";
import Stars5 from "/static/star_ratings/summit_stars_5.svg";

const getStarsImage = (starRating) => {
  switch (starRating) {
    case "4.1":
      return Stars4_1;
    case "4.2":
      return Stars4_2;
    case "4.3":
      return Stars4_3;
    case "4.4":
      return Stars4_4;
    case "4.5":
      return Stars4_5;
    case "4.6":
      return Stars4_6;
    case "4.7":
      return Stars4_7;
    case "4.8":
      return Stars4_8;
    case "4.9":
      return Stars4_9;
    case "5":
      return Stars5;
    default:
      return null;
  }
};

const TestimonialsSimpleCentered = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <section
        className={`py-12 overflow-hidden lg:py-16 ${BackgroundColour(
          blok.colour === "yellow" ? "darkblue" : blok.colour
        )}`}
      >
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <blockquote className="py-2">
              <div
                className={`max-w-4xl mx-auto text-center font-display lg:text-4.5xl md:text-4xl text-3xl lg:leading-9 md:leading-8 leading-7 font-black italic uppercase ${TitleColour(
                  blok.colour === "yellow" ? "darkblue" : blok.colour
                )}`}
              >
                {blok.content}
              </div>
              {blok.name && (
                <footer className="mt-10">
                  <div className="flex items-center justify-center">
                    <div
                      className={`font-body tracking-wide text-center flex items-center`}
                    >
                      <div className="font-semibold">{blok.name}</div>
                      {blok.job_title && (
                        <div className="">&nbsp;-&nbsp;{blok.job_title}</div>
                      )}
                    </div>
                  </div>
                  {blok.star_rating && (
                    <div className="mt-10 flex items-center justify-center">
                      <img
                        className="h-[36px] w-full"
                        src={getStarsImage(blok.star_rating)}
                        alt="Star Rating"
                      />
                    </div>
                  )}
                </footer>
              )}
            </blockquote>
          </div>
        </div>
      </section>
    </SbEditable>
  );
};

export default TestimonialsSimpleCentered;
