import React from 'react'
import SbEditable from 'storyblok-react'

const YouTubeVideo = ({ blok }) => {
    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className="relative bg-white py-16 sm:py-20">
                <div className="relative max-w-7xl mx-auto px-6">
                    <div className='block aspect-w-16 aspect-h-9'>
                        <iframe
                            width="853"
                            height="480"
                            src={`https://www.youtube.com/embed/${blok.embed_id}`}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded YouTube video"
                        />
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default YouTubeVideo