import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from 'react-countup';
import SbEditable from "storyblok-react";
import { TitleColour } from "src/utils/ColourUtilities";
import RichText from "src/utils/RichText"
import Heading from "src/components/Heading"

const Stat = ({ blok, colour }) => {

  const [active, setActive] = useState(true)

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="md:w-1/3 w-full text-center flex flex-col items-center justify-center py-6">
        <CountUp start={active && 0} end={parseInt(blok.number)} duration={3} prefix={blok.char_before} suffix={blok.char_after}>
          {({ countUpRef }) => (
              <VisibilitySensor onChange={isVisible => isVisible && setActive(false)} delayedCall>
                  <dt className={`text-8xl font-display font-black italic ${TitleColour(colour)}`} ref={countUpRef} />
              </VisibilitySensor>
          )}
        </CountUp>
        <Heading size="h5" className="my-4 not-italic font-semibold font-body">{blok.title}</Heading>
        <dd className="font-body max-w-xs prose leading-6">
          <RichText>{blok.description}</RichText>
        </dd>
      </div>
    </SbEditable>
  );
};

export default Stat;
