// Ref: https://thewebdev.info/2021/01/31/create-an-audio-player-with-react-and-javascript/
import React, { useState, useRef } from 'react'
import SbEditable from 'storyblok-react'
import Heading from 'src/components/Heading'
import FluidImage from 'src/utils/FluidImage'

const Audio = ({ blok }) => {

    const audioPlayer = useRef()
    const [currentTime, setCurrentTime] = useState('00:00:00')
    const [seekValue, setSeekValue] = useState(0)
    const [duration, setDuration] = useState('00:00:00')
    const [playbackSpeed, setPlaybackSpeed] = useState(1)

    // Unified function for play/pause
    const playPauseButton = () => {
        if (audioPlayer.current.paused) {
            audioPlayer.current.play()
        } else {
            audioPlayer.current.pause()
        }
    }
    
    // Change the playback speed. Double declares for improved UX.
    // If there is no setPlaybackSpeed() it will not display properly without the audio playing
    const setSpeed = () => {
        if (playbackSpeed === 1) {
            audioPlayer.current.playbackRate = 1.5
            setPlaybackSpeed(1.5)
        } else if (playbackSpeed === 1.5) {
            audioPlayer.current.playbackRate = 2
            setPlaybackSpeed(2)
        } else if (playbackSpeed === 2) {
            audioPlayer.current.playbackRate = 0.5
            setPlaybackSpeed(0.5)
        } else if (playbackSpeed === 0.5) {
            audioPlayer.current.playbackRate = 1
            setPlaybackSpeed(1)
        }
    }

    // Function to turn the time values to HH:MM:SS
    const toHHMMSS = (props) => {
        let sec_num = parseInt(props, 10)
        let hours   = Math.floor(sec_num / 3600)
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
        let seconds = sec_num - (hours * 3600) - (minutes * 60)

        if (hours   < 10) { hours   = "0" + hours }
        if (minutes < 10) { minutes = "0" + minutes }
        if (seconds < 10) { seconds = "0" + seconds }
        return hours + ':' + minutes + ':' + seconds
    }

    // This is what updates everything
    const onPlaying = () => {
        setCurrentTime(toHHMMSS(audioPlayer.current.currentTime))
        setSeekValue((audioPlayer.current.currentTime / audioPlayer.current.duration) * 100)
        setDuration(toHHMMSS(audioPlayer.current.duration))
    }

    return (
        <SbEditable content={blok}>
            <div className="py-16 xl:py-24 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="p-2 bg-[#f0f0f0]">
                        <div className="flex flex-row bg-darkblue text-white">
                            <div className="flex flex-col w-full gap-3 p-6 items-center justify-center">
                                <div className="flex md:flex-row flex-col items-center w-full">
                                    <div className="flex flex-row items-center justify-center w-full">
                                        <div className="mr-4">
                                            <button 
                                                onClick={() => playPauseButton()}
                                                className="text-6xl w-[67px]"
                                            >
                                                <i className={`fas ${audioPlayer?.current?.paused ? 'fa-play-circle' : 'fa-pause-circle'}`}></i>
                                            </button>
                                        </div>
                                        <div className="w-full">
                                            <h5 className="font-body font-semibold text-sm">{blok.caption}</h5>
                                            <Heading size="h2" as="h3" className="mb-0">{blok.name}</Heading>
                                            {blok.date && <time className="font-body text-sm">{new Date(blok.date).toLocaleDateString("en-AU")}</time>}
                                        </div>
                                    </div>
                                    <div className="font-body text-sm flex md:items-end md:justify-end md:mt-0 mt-4 lg:w-3/12 md:w-4/12 w-full">
                                        <button 
                                            onClick={() => setSpeed()}
                                            className="bg-white text-darkblue pt-1 px-2"
                                        >
                                            Playback speed: {playbackSpeed}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-row w-full items-center">
                                    <span className="mr-4">{currentTime}</span>
                                    <input
                                        className="cursor-pointer w-full shadow-none border-white bg-white appearance-none h-1 audio outline-none focus:outline-none"
                                        type="range"
                                        min="0"
                                        max="100"
                                        step="1"
                                        value={seekValue}
                                        onChange={(e) => {
                                            const seekto = audioPlayer.current.duration * (+e.target.value / 100);
                                            audioPlayer.current.currentTime = seekto;
                                            setSeekValue(e.target.value);
                                        }}
                                    />
                                    <span className="ml-4">{duration}</span>
                                </div>
                            </div>
                            {blok.image && blok.image.filename &&
                                <div className="w-[200px] lg:flex hidden">
                                    <FluidImage image={blok.image} className="w-full"/>
                                </div>
                            }
                        </div>
                    </div>
                    {/* This is the actual audio element */}
                    <audio
                        src={blok.file?.filename}
                        ref={audioPlayer}
                        onTimeUpdate={() => onPlaying()}
                        className="hidden"
                    >
                        <track default kind="captions" srclang="en" src="" />
                        Your browser does not support the <code>audio</code> element.
                    </audio>
                </div>
            </div>
        </SbEditable>
    )
}

export default Audio