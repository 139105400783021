
/*  TWO COLUMNS ( https://tailwindui.com/components/marketing/sections/content-sections#component-176f404f597838293fd652b71bf0726f )  */

import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText'
import Label from '/src/components/Label'
import Heading from 'src/components/Heading'
import Button from 'src/components/Button'

const TwoColumns = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            <div className="py-16 xl:py-24 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="relative z-10 md:mb-8 mb-4 lg:mb-2 lg:px-6">
                        <div className="text-base max-w-prose lg:max-w-none">
                            <Label className="text-pink mb-2">{blok.label}</Label>
                            <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h2'} className="text-darkblue">{blok.title}</Heading>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="relative lg:p-6">
                            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                <div className="prose max-w-7xl text-gray lg:max-w-none leading-6 font-body">
                                    <RichText>{blok.content_left}</RichText>
                                </div>
                                <div className="mt-6 prose max-w-7xl text-gray lg:mt-0 leading-6 font-body">
                                    <RichText>{blok.content_right}</RichText>
                                </div>
                            </div>
                            {blok.button_text &&
                                <Button to={blok.button_target?.cached_url} className="mt-6" type="pink">{blok.button_text}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default TwoColumns