import React from "react";
import SbEditable from "storyblok-react";
import { BackgroundColour, TitleColour } from "src/utils/ColourUtilities";
import Stars4_1 from "/static/star_ratings/summit_stars_4_1.svg";
import Stars4_2 from "/static/star_ratings/summit_stars_4_2.svg";
import Stars4_3 from "/static/star_ratings/summit_stars_4_3.svg";
import Stars4_4 from "/static/star_ratings/summit_stars_4_4.svg";
import Stars4_5 from "/static/star_ratings/summit_stars_4_5.svg";
import Stars4_6 from "/static/star_ratings/summit_stars_4_6.svg";
import Stars4_7 from "/static/star_ratings/summit_stars_4_7.svg";
import Stars4_8 from "/static/star_ratings/summit_stars_4_8.svg";
import Stars4_9 from "/static/star_ratings/summit_stars_4_9.svg";
import Stars5 from "/static/star_ratings/summit_stars_5.svg";

const getStarsImage = (starRating) => {
  switch (starRating) {
    case "4.1":
      return Stars4_1;
    case "4.2":
      return Stars4_2;
    case "4.3":
      return Stars4_3;
    case "4.4":
      return Stars4_4;
    case "4.5":
      return Stars4_5;
    case "4.6":
      return Stars4_6;
    case "4.7":
      return Stars4_7;
    case "4.8":
      return Stars4_8;
    case "4.9":
      return Stars4_9;
    case "5":
      return Stars5;
    default:
      return null;
  }
};

const StarRating = ({ blok }) => {
    return (
      <SbEditable content={blok} key={blok._uid}>
            <div className="bg-[#FAFAFA]">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-4">
                    <div className="py-6">
                        <img
                        className="h-[36px] mb-3"
                        src={getStarsImage(blok.star_rating)}
                        alt="Star Rating"
                        />
                        <div className="w-full ml-2 uppercase font-body">
                        <p><span className="font-semibold">{blok.star_rating} stars</span> - based on {blok.participant_number} participants</p>
                        </div>
                    </div>
                </div>
            </div>
       </SbEditable>
  );
};

export default StarRating;